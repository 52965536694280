import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {
    addMultiPlage,
    addPlage,
    changeValoAbs,
    getInfosPlageAbs,
    getPlageId,
    getPlagePeriodeAbs,
    getPlagesByParams2,
    getPlagesDate,
    getPlagesDateLieu,
    getPlagesDateToDate,
    getPlagesDateToDateMulti,
    getPlagesReplacedDate,
    getPlageWeek,
    getPlageWeekOne,
    modifyMultiPlage,
    modifyPlage,
    outTeam,
    putTeam,
    replacePlage,
    trashMultiPlage,
    trashPlage
} from "./plage.services";
import {toast} from "react-toastify";
import {getISOWeekNumber, getNumWeek} from "../../components/functions/TimesFunctions";

export const useGetOnPlageId=(id:number):UseQueryResult<Plage, Error>=>{
    return useQuery<Plage, Error>(["plage", id], ()=>getPlageId(id), {
        enabled:id!==-1
    })
}

export const useGetPlageParams2 = (Params:any|null):UseQueryResult<Plage[], Error>=>{
    return useQuery<Plage[], Error>(["plages_all", Params], ()=>getPlagesByParams2(Params), {
        enabled:Params!==null,
        refetchOnWindowFocus:false,
    })
}

export const useGetPlageByParams = (Params:ParamsPlage|null):UseQueryResult<Plage[], Error>=>{
    return useQuery<Plage[], Error>(["plages_params", Params], ()=>getPlagesByParams2(Params), {
        enabled:Params!==null,
        refetchOnWindowFocus:false,
    })
}

export const useGetPlageWeek=(dateTo:string|null, idCentre:number|null=null, filters=false, filterV=false):UseQueryResult<Plage[], Error>=>{
    const numWeek = !dateTo ? "S0" :  "S"+getISOWeekNumber(new Date(dateTo));
    return useQuery(["plages_week", {numSem:numWeek, idCentre:idCentre, filters:filters, filterV:filterV}], ()=>getPlageWeek(dateTo, idCentre, filters, filterV), {
        staleTime:60000,
        enabled:dateTo!==null,
        refetchOnWindowFocus:false
    })
}

export const useGetPlageWeekOne=(dateTo:string|null, id:number):UseQueryResult<Plage[], Error>=>{
    return useQuery(["plages", dateTo, id], ()=>getPlageWeekOne(dateTo, id), {
        staleTime:5000,
        enabled:dateTo!==null && id!==0,
    })
}

export const useGetPlagesAllDateDate = (dateFrom:string, dateTo:string, idCentre:number|null=null):UseQueryResult<Plage[], Error>=>{
    return useQuery(["plages_all", dateFrom, dateTo, idCentre], ()=>getPlagesDateToDate(dateFrom, dateTo, undefined, false, idCentre), {
        staleTime:5000,
        enabled:dateFrom!=='' && dateTo!=='' && dateFrom!==undefined && dateTo!==undefined,
    })
}

export const useGetPlagePeriodeAbs = (Periode:Periode, idUser:number):UseQueryResult<Plage[], Error>=>{
    return useQuery<Plage[], Error>(["plage_periode", Periode.id], ()=>getPlagePeriodeAbs(Periode, idUser), {
        staleTime:5000,
        enabled:idUser!==0
    })
}

export const useGetPlagesDateToDateOne=(dateFrom:string, dateTo:string, id:number|undefined, idCentre:number|null=null):UseQueryResult<Plage[], Error>=>{
    return useQuery(["plages", dateFrom, dateTo, id, idCentre], ()=>getPlagesDateToDate(dateFrom, dateTo, id, false, idCentre||0), {
        staleTime:5000,
        enabled:id!==undefined && id!==0 && dateFrom!=='' && dateTo!=='' && dateFrom!==undefined && dateTo!==undefined,
        refetchOnWindowFocus:false,
    })
}
export const useGetPlagesDateToDateOneNotStrict=(dateFrom:string, dateTo:string, id:number|undefined, idCentre:number|null=null):UseQueryResult<Plage[], Error>=>{
    return useQuery(["plages", dateFrom, dateTo, id], ()=>getPlagesDateToDate(dateFrom, dateTo, id, true, idCentre||0), {
        staleTime:5000,
        enabled:id!==undefined && id!==0 && dateFrom!=='' && dateTo!=='' && dateFrom!==undefined && dateTo!==undefined,
        refetchOnWindowFocus:false,
    })
}

export const useGetPlagesDateToDateMulti=(dateFrom:string|undefined, dateTo:string|undefined, ids:number[], idCentre:number|null=null):UseQueryResult<Plage[], Error>=>{
    return useQuery(["plages_multi", dateFrom, dateTo, ids, idCentre], ()=>getPlagesDateToDateMulti(dateFrom, dateTo, ids, idCentre), {
        staleTime:5000,
        enabled:dateFrom!==undefined && dateTo!==undefined && ids.length>0,
    })
}

export const useGetPlageDate=(date:string, id:number|undefined):UseQueryResult<Plage[], Error>=>{
    return useQuery(["plages_oneday", date, id], ()=>getPlagesDate(date, id), {
        staleTime:5000,
        enabled:id!==undefined && id!==0 && date!=='',
    })
}

export const useGetPlageRDate=(date:string, id:number|undefined):UseQueryResult<Plage[], Error>=>{
    return useQuery(["plages_replaced", date, id], ()=>getPlagesReplacedDate(date, id), {
        staleTime:5000,
        enabled:id!==undefined && id!==0 && date!=='',
    })
}

export const useGetPlageDateWait=(date:string, id:number|undefined, go:boolean):UseQueryResult<Plage[], Error>=>{
    return useQuery(["plages_oneday", date, id], ()=>getPlagesDate(date, id), {
        staleTime:5000,
        enabled:id!==undefined && id!==0 && date!=='' && go,
    })
}

export const useGetPlageDateLieu=(date:string, idLieu:number|null, idCentre:number|null=null):UseQueryResult<Plage[], Error>=>{
    return useQuery(["plages_onedaylieu", date, idLieu, idCentre], ()=>getPlagesDateLieu(date, idLieu, idCentre), {
        staleTime:5000,
        enabled:idLieu!==null && date!=='',
    })
}

export const useModifyPlage=(inv:boolean=true)=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:ModifyPlageFormData)=>{
            return modifyPlage(datas)
        },{
            onSuccess:(newPlage:Plage)=>{
                if(inv) {
                    //queryClient.invalidateQueries(["plages"])
                    queryClient.setQueriesData<Plage[]>(["plages_week"], (oldPlage:Plage[]|undefined)=>{
                        if(oldPlage){
                            return [...oldPlage.filter(p=>p.id!==newPlage.id), newPlage]
                        } else {
                            return []
                        }
                    })
                }
                queryClient.invalidateQueries(["plages_multi"])
                queryClient.invalidateQueries(["plages_all"])
                queryClient.invalidateQueries(["cpt_tps_dd"])
                queryClient.invalidateQueries(["metrix_hebdo"])
                queryClient.invalidateQueries(["plages"])
                queryClient.invalidateQueries(["anomalies"])
                queryClient.invalidateQueries(["sugg_paires"])
                queryClient.setQueriesData(["plage", newPlage.id], newPlage)
                //toast.success('Plage modifiée')
            },
            onError:(error:any)=>{
                toast.error("Erreur : " + error.message, {});
            }
        }
    )
}

export const useTrashPlage=()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return trashPlage(id)
        },{
            onSuccess:()=>{
                queryClient.invalidateQueries(["plages"])
                queryClient.invalidateQueries(["plages_week"])
                queryClient.invalidateQueries(["plages_multi"])
                queryClient.invalidateQueries(["plages_all"])
                queryClient.invalidateQueries(["creneaux"])
                queryClient.invalidateQueries(["cpt_tps_dd"])
                queryClient.invalidateQueries(["metrix_hebdo"])
                queryClient.invalidateQueries(["anomalies"])
                toast.success('Plage supprimée')
            },
            onError:(error:any)=>{
                toast.error("Erreur : " + error.message, {});
            }
        }
    )
}

export const useAddPlage=(inv:boolean=true)=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:AddPlageFormData)=>{
            return addPlage(datas)
        },{
            onSuccess:()=>{
                if(inv) {
                    queryClient.invalidateQueries(["plages"])
                    queryClient.invalidateQueries(["plages_week"])
                }
                queryClient.invalidateQueries(["plages_multi"])
                queryClient.invalidateQueries(["cpt_tps_dd"])
                queryClient.invalidateQueries(["metrix_hebdo"])
                queryClient.invalidateQueries(["anomalies"])
               // toast.success('Plage ajoutée')
            },
            onError:(error:any)=>{
                toast.error("Erreur : " + error.message, {});
            }
        }
    )
}

export const useAddMultiPlage=()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:AddPlageFormMulti[])=>{
            return addMultiPlage(datas)
        },{
            onSuccess:()=>{
                queryClient.invalidateQueries(["plages"])
                queryClient.invalidateQueries(["plages_week"])
                queryClient.invalidateQueries(["plages_multi"])
                queryClient.invalidateQueries(["cpt_tps_dd"])
                queryClient.invalidateQueries(["metrix_hebdo"])
                queryClient.invalidateQueries(["pointeuse_treat"])
                queryClient.invalidateQueries(["cpt_tps_dd"])
                toast.success('Plage(s) ajoutée(s)')
            },
            onError:(error:any)=>{
                toast.error("Erreur : " + error.message, {});
            }
        }
    )
}

export const useModifyMultiPlage=()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:ModifyMultiPlageFD)=>{
            return modifyMultiPlage(datas)
        },{
            onSuccess:()=>{
                queryClient.invalidateQueries(["plages"])
                queryClient.invalidateQueries(["plages_week"])
                queryClient.invalidateQueries(["plages_multi"])
                queryClient.invalidateQueries(["pointeuse_treat"])
                queryClient.invalidateQueries(["cpt_tps_dd"])
                toast.success('Plage(s) modifiée(s)')
            },
            onError:()=>{
                toast.error("Une erreur est survenue, merci de retenter plus tard");
            }
        }
    )
}

export const useTrashMultiPlage=()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (ids:string)=>{
            return trashMultiPlage(ids)
        },{
            onSuccess:()=>{
                queryClient.invalidateQueries(["plages"])
                queryClient.invalidateQueries(["plages_week"])
                queryClient.invalidateQueries(["plages_multi"])
                queryClient.invalidateQueries(["pointeuse_treat"])
                queryClient.invalidateQueries(["cpt_tps_dd"])
                toast.success('Plage(s) supprimée(s)')
            },
            onError:()=>{
                toast.error("Erreur est survenue");
            }
        }
    )
}

export const useReplaceUserPlage=()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:PlageReplaceFormData)=>{
            return replacePlage(datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["plages"])
                queryClient.invalidateQueries(["plages_week"])
                queryClient.invalidateQueries(["plages_all"])
            },
            onError:()=>{
                toast.error("Une erreur est survenue");
            }
        }
    )
}

export const useOutTeam=()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return outTeam(id)
        },{
            onSuccess:()=>{
                queryClient.invalidateQueries(["plages"])
                queryClient.invalidateQueries(["plages_week"])
            },
            onError:(error:any)=>{
                toast.error("Erreur : " + error.message, {});
            }
        }
    )
}

export const usePutTeam=()=>{
    return useMutation(
        (datas:PutTeamFormData)=>{
            return putTeam(datas)
        }
    )
}

export const useGetInfosPlageAbs = (id:number):UseQueryResult<InfosPlageAbs, Error>=>{
    return useQuery(["infos_abs", id], ()=>getInfosPlageAbs(id), {
        enabled:id!==0
    })
}

export const useChangeValoAbs = (id:number)=>{
    const queryClient = useQueryClient();
    return useMutation(
        (myTime:number)=>{
            return changeValoAbs(id, myTime);
        },{
            onSuccess:()=>{
                queryClient.invalidateQueries(["plages"])
                queryClient.invalidateQueries(["plages_week"])
                queryClient.invalidateQueries(["plages_multi"])
                queryClient.invalidateQueries(["cpt_tps_dd"])
                queryClient.invalidateQueries(["metrix_hebdo"])
                toast.success('Plage(s) modifiée(s)')
            },
            onError:()=>{
                toast.error("Erreur est survenue");
            }
        }
    )
}